<template>
  <!-- NewCurrency Modal -->
  <div class="modal fade" data-backdrop="static" :id="getModalID()" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="submitForm" @keydown="currency.errors.clear($event.target.name);">
        <div class="modal-header">
          <h5 class="modal-title">{{ action.toUpperCase() }} CURRENCY</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetForm">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row no-gutters mb-1">
            <label class="font-weight-bold col-sm-3 col-form-label">Name</label>
            <div class="col-sm-9">
              <input type="text" v-model="currency.name" name="name" class="form-control form-control-sm" required :class="{ 'is-invalid': currency.errors.has('name') }">
              <div v-if="currency.errors.has('name')" :class="{ 'invalid-feedback': currency.errors.has('name') }">
                {{ currency.errors.first('name') }}
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label class="font-weight-bold col-sm-3 col-form-label">Symbol</label>
            <div class="col-sm-9">
              <input type="text" v-model="currency.symbol" name="symbol" class="form-control form-control-sm" required :class="{ 'is-invalid': currency.errors.has('symbol') }" placeholder="e.g. $, ¥">
              <div v-if="currency.errors.has('symbol')" :class="{ 'invalid-feedback': currency.errors.has('symbol') }">
                {{ currency.errors.first('symbol') }}
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label class="font-weight-bold col-sm-3 col-form-label">Code</label>
            <div class="col-sm-9">
              <input type="text" v-model="currency.code" name="code" class="form-control form-control-sm" required :class="{ 'is-invalid': currency.errors.has('code') }" placeholder="e.g. USD, JPY">
              <div v-if="currency.errors.has('code')" :class="{ 'invalid-feedback': currency.errors.has('code') }">
                {{ currency.errors.first('code') }}
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label class="font-weight-bold col-sm-3 col-form-label">Status</label>
            <div class="col-sm-9 ">
              <div class="form-check form-check-inline mt-2">
                <input class="form-check-input" type="radio" name="active" value="1" v-model="currency.active" :id="getModalID('-active-1')">
                <label class="form-check-label" :for="getModalID('-active-1')">Active</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="active" value="0" v-model="currency.active" :id="getModalID('-active-0')">
                <label class="form-check-label" :for="getModalID('-active-0')">Inactive</label>
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label class="font-weight-bold col-sm-3 col-form-label">Country</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm text-uppercase" name="country_id" v-model="currency.country_id"
                      :class="{ 'is-invalid': currency.errors.has('country_id') }">
                <option :value="null">—— Select Country ——</option>
                <option v-for="country in countryKeys" :key="country.id" :value="country.id">
                  {{ country.name }}
                </option>
              </select>
              <div v-if="currency.errors.has('country_id')" :class="{ 'invalid-feedback': currency.errors.has('country_id') }">
                {{ currency.errors.first('country_id') }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" ref="Currency_close" data-dismiss="modal" @click="resetForm">Close</button>
          <button type="submit" class="btn btn-sm btn-primary" :disabled="currency.errors.any()">{{ action }} Currency</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {CurrencyService} from '@/services/CurrencyService'
import {AlertService} from '@/services/AlertService'
import {Form} from "form-backend-validation";

export default {
  name: 'ModalCurrency',
  data() {
    return {
      currency: new Form({
        name: null,
        symbol: null,
        code: null,
        active: 1,
        country_id: null,
      })
    };
  },
  props: {
    action: {
      type: String,
      default: 'Modal',
    },
    newCurrency: {}
  },
  methods: {
    ...mapActions(['getCountryListKeys']),
    getModalID(append = '') {
      return this.action + 'Currency' + append;
    },
    async submitForm() {
      const action = this.action.toLowerCase()
      const title = `${action.toUpperCase()} CURRENCY`
      const success_message = `Currency has been ${action}d`

      // CurrencyService[action] -> CurrencyService.create | CurrencyService.update
      await (CurrencyService[action](this.currency))
        .then(response => {
          this.$refs.Currency_close.click()
          AlertService.successAlert(success_message, title)
          this.$emit('resetPage')
        })
        .catch(error => {
          AlertService.errorAlert(error.response.data.message, title)
        });
    },
    resetForm(){
      this.currency=new Form({
        name: null,
        symbol: null,
        code: null,
        active: 1,
        country_id: null,
      })
    }
  },
  async created() {
    await this.getCountryListKeys()
  },
  computed: {
    ...mapGetters(['countryKeys']),
  },
  watch: {
    newCurrency(newCurrency, OldCurrency) {
      if (typeof newCurrency !== "undefined") {
        this.currency = new Form(newCurrency)
      }
    }
  }
}
</script>

<style scoped>

</style>
