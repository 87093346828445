<template>
  <div class="row">
    <div class="col-sm-9">
      <h3 class="text-left">Currency Settings</h3>
    </div>
    <div class="col-sm-3 text-right">
      <button class="e-btn e-btn-green e-text-white pr-3 mt-2" data-toggle="modal" data-target="#CreateCurrency">
        <font-awesome-icon icon="plus-square" style="width: 25px"/>
        <small class="font-weight-bold">
          ADD CURRENCY
        </small>
      </button>
    </div>

    <div class="col-12 pl-0 text-center col-lg-12">
      <nav aria-label="...">
        <ul class="pagination text-smallest mb-2 pb-0">
          <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
            <button class="page-link" @click="setPage(1)">First</button>
          </li>

          <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
            <button class="page-link" @click="setPage(page)">{{ page }}</button>
          </li>

          <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
            <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
          </li>
        </ul>
      </nav>
      <table class="table table-sm table-bordered small">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">NAME</th>
          <th scope="col">SYMBOL</th>
          <th scope="col">CODE</th>
          <th scope="col">COUNTRY</th>
          <th scope="col">STATUS</th>
          <th scope="col">ACTION</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="loading">
          <td colspan="100%">
            <loading></loading>
          </td>
        </tr>
        <template v-else>
          <template v-for="(currency, index) in currencies">
            <tr>
              <th scope="row">{{ index + pagination.from }}</th>
              <td>{{ currency.name }}</td>
              <td>{{ currency.symbol }}</td>
              <td>{{ currency.code }}</td>
              <td>{{ (currency.country) ? currency.country.name : '' }}</td>
              <td>{{ currency.active ? 'Active' : 'Inactive' }}</td>
              <td class="text-white">
                <div class="btn-group dropleft">
                  <button
                    id="actionDropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="mr-2 btn btn-sm btn-secondary force-white-all"
                    data-toggle="dropdown"
                  >
                    <font-awesome-icon icon="cogs"/>
                  </button>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            data-toggle="modal" data-target="#UpdateCurrency" @click="passNewCurrency(currency)">
                      <font-awesome-icon icon="pen"/>
                      Update
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            @click="deleteCurrency(currency.id)"
                    >
                      <font-awesome-icon icon="trash" class="force-danger-all"/>
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
    </div>
    <modal-currency action="Create" @resetPage="setPage"/>
    <modal-currency action="Update" @resetPage="setPage" :newCurrency="newCurrency"/>
  </div>
</template>

<script>
import AppLoading from "@/components/elements/AppLoading";
import ModalCurrency from '@/components/settings/currency/ModalCurrency';

import {mapActions, mapGetters} from "vuex";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import {AlertService} from "@/services/AlertService";
import {CurrencyService} from "@/services/CurrencyService";

export default {
  name: "SettingsCurrencies",
  data() {
    return {
      loading: false,
      filters: {
        page: 1
      },
      modalUpdate: false,
      newCurrency: Object,
    }
  },
  components: {
    'loading': AppLoading,
    'modal-currency': ModalCurrency,
  },
  mixins: [PaginationMixin],
  methods: {
    ...mapActions(['getCurrenciesList']),
    async setPage(page = 1 ) {
      this.loading = true
      this.filters.page = page
      this.filters.perPage=10;
      await this.getCurrenciesList(this.filters)
      this.loading = false
    },
    passNewCurrency(obj) {
      this.newCurrency = obj
    },
    async deleteCurrency(id) {
      const title = 'Deleting Currency'

      if (await AlertService.confirmDeleteAlert()) {
        await CurrencyService.delete(id)
          .then(response => {
            AlertService.successAlert(response.data.message, title)
            this.setPage()
          })
          .catch(error => {
            // console.log(error.response)
            AlertService.errorAlert(error.response.data.message, title)
          });
      }
    },
  },
  computed: {
    ...mapGetters(['currencies','pagination']),
  },
  created() {
    this.setPage()
  }
}
</script>

<style scoped>

</style>
